import React from "react";
import Screen3 from "../screens/3.png";
import Screen4 from "../screens/4.png";
import Screen5 from "../screens/5.png";

const ScreenshotGallery = () => {
  return (
    <section className="screenshot-gallery">
      <div className="screenshots">
        <img src={Screen3} alt="Screenshot 1" />
        <img src={Screen4} alt="Screenshot 2" />
        <img src={Screen5} alt="Screenshot 3" />
      </div>
    </section>
  );
};

export default ScreenshotGallery;
