import React from "react";

const DownloadButton = () => (
  <a
    href="https://apps.apple.com/se/app/urban-explorer-sverige/id6547833109?itscg=30200&itsct=apps_box_badge&mttnsubad=6547833109"
    style={{ display: "inline-block" }}
    target="_blank" // Optional: open in a new tab
    rel="noopener noreferrer" // Optional: security best practice for external links
  >
    <img
      src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/white/sv-se?releaseDate=1723507200"
      alt="Download on the App Store"
      style={{
        width: "245px",
        height: "82px",
        verticalAlign: "middle",
        objectFit: "contain",
      }}
    />
  </a>
);

export default DownloadButton;
