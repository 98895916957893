import DownloadButton from "./DownloadButton";

const AppLink = () => (
  <a
    href="https://apps.apple.com/se/app/urban-explorer-sverige/id6547833109?itscg=30200&itsct=apps_box_artwork&mttnsubad=6547833109"
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textDecoration: "none",
      color: "inherit",
      padding: "15px",
    }}
  >
    {/* App Icon */}
    {/* <div
      style={{
        position: "relative",
        width: "70px",
        height: "70px",
        overflow: "hidden",
        marginRight: "15px", // Adds space between the icon and the text
      }}
    >
      <img
        src="https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/7d/20/9a/7d209a9c-beee-b313-903a-8ddce116b3c0/AppIcon-0-0-1x_U007emarketing-0-5-0-85-220.png/540x540bb.jpg"
        alt="Urban Explorer: Sverige"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          maskImage: "var(--app-icon-mask)",
          WebkitMaskImage: "var(--app-icon-mask)",
          borderRadius: "10px",
        }}
      />
    </div> */}

    {/* App Text */}
    <div
      style={{
        marginBottom: "10px",
        fontSize: "16px",
        color: "#333",
        lineHeight: "1.4",
      }}
    >
      <span style={{ fontWeight: "bold", color: "white" }}>
        Urban Explorer: Sverige
      </span>
      <p style={{ margin: "0", fontSize: "14px", color: "#777" }}>
        Gratis att använda!
      </p>
    </div>

    {/* Download Button */}
    <DownloadButton />
  </a>
);

export default AppLink;
