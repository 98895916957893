import React from "react";
import "./App.css";
import Header from "./components/Header";
import AppDescription from "./components/AppDescription";
import ScreenshotGallery from "./components/ScreenshotGallery";
import Footer from "./components/Footer";
import AppLink from "./components/AppLink";

function App() {
  return (
    <div className="App">
      <ScreenshotGallery />
      <AppLink />
    </div>
  );
}

export default App;
